// v1.0.0 02-12-2024

// JS Cookie
import cookie from "js-cookie";
import { jwtDecode } from "jwt-decode";

// Context
import GetTenant from "./GetTenant";
import { GetEnvironment } from "./GetEnvironment";

// Sentry
import * as Sentry from "@sentry/nextjs";

export default function logout(locale?: string) {
  console.log("user cookie logging out", cookie.get("currentUser"));
  if (typeof window !== "undefined" && window.self === window.top) {
    const querystring: string =
      "?returnUrl=[" +
      `${window.location.origin}${window.location.pathname}` +
      "]&returnQuery=[" +
      encodeURIComponent(window.location.search) +
      "]";

    cookie.remove("currentUser");
    localStorage.clear();
    redirectToLogin(locale || "fr", querystring);
  }
}

// Function to decode JWT and set auto-logout
export function setAutoLogout(token: string) {
  const locale = getLocale(window.location.pathname);
  if (typeof window === "undefined") {
    return;
  }

  try {
    const expiration = jwtDecode(token);
    console.log("expiration", expiration, token);

    // Get current time and token expiration time
    const currentTime = Math.floor(Date.now() / 1000);
    const expirationTime = expiration.exp || currentTime; // current time if expiration date is undefined
    const timeUntilExpiration = (expirationTime - currentTime) * 1000; // in milliseconds

    console.log("timeUntilExpiration", timeUntilExpiration);
    if (timeUntilExpiration > 0) {
      localStorage.setItem("tokenExpiration", expirationTime.toString());
      // Set timeout to auto-logout the user
      setTimeout(() => logout(locale), timeUntilExpiration);
    } else if (window.self === window.top) {
      // Token is already expired
      logout(locale);
    }
  } catch (error) {
    console.error("Invalid token", error);
    Sentry.captureException(error);
  }
}

export function redirectToLogin(locale: string, redirectLink?: string) {
  const pageNoRedirect = ["support", "status"];
  const tenant = GetTenant();

  if (typeof window !== "undefined") {
    const environment =
      GetEnvironment(window.location.href) === "prod"
        ? "."
        : `.${GetEnvironment(window.location.href)}.`;

    let shouldRedirect = true;
    pageNoRedirect.forEach((page) => {
      if (window.location.href.includes(page)) {
        shouldRedirect = false;
      }
    });
    if (shouldRedirect) {
      window.location.href = `https://${tenant}${environment}login.phpr.link/${locale}${redirectLink}`;
    }
  }
}

export function getLocale(url: string) {
  const locale = url.split("/")[1];
  return locale;
}
